import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import ServerClient from '../services/api-client';
import useNotificationStore from '../store/useNotificationStore';
import useUserStore from '../store/useUserStore';

// retrieve notifications
export function useRetrieveNotifications() {
  const { userId } = useUserStore();
  const setNotifications = useNotificationStore((state) => state.setNotifications);

  return useQuery({
    queryKey: ['retrieveNotifications'],
    queryFn: async () => {
      const serverClient = new ServerClient('/api/retrieveNotifications');
      const res = await serverClient.post({ userId: userId });
      return res.data;
    },
    onSuccess: (data) => {
      console.log('retrieveNotifications', data);
      setNotifications(data);
    },
    onError: (error) => {
      console.error('Error fetching data:', error);
    },
  });
}

// mark notifications as read
export function useMarkNotificationsAsRead() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ userId, notificationId }) => {
      const serverClient = new ServerClient('/api/markNotificationAsRead');
      const res = await serverClient.post({ userId, notificationId });
      return res.data;
    },
    onSuccess: (data) => {
      console.log('Notification marked as read:', data);
      queryClient.invalidateQueries(['retrieveNotifications']);
    },
    onError: (error) => {
      console.error('Error mark notification as read:', error);
    },
  });
}

// settings notification
export function useEmailNotificationsEnabled() {
  return useMutation({
    mutationFn: async ({ userId, toggleUpdate }) => {
      const serverClient = new ServerClient('/api/db/emailNotificationsEnabled');
      const res = await serverClient.post({ userId, toggleUpdate });
      return res.data;
    },
    onSuccess: () => {
      console.log('useEmailNotificationsEnabled hooks OK.');
    },
    onError: (error) => {
      console.error('Error useEmailNotificationsEnabled hooks.', error);
    },
  });
}
