import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import Select from 'react-select';
import useUserStore from '../../store/useUserStore';
import useFormBuilderStore from '../../store/useFormBuilderStore';
import useClientStore from '../../store/useClientStore';
import ServerClient from '../../services/api-client';
import { useSocket } from '../../SocketContext';
import Button from '../../components/Button/Button';
import User1 from '../../assets/Avatar/Avatar_Dummy_1.svg';
// import User2 from '../../assets/Avatar/Avatar_Dummy_2.svg';
// import User3 from '../../assets/Avatar/Avatar_Dummy_3.svg';

const ShareTeam = ({ isDocumentModal, onClose, templateId, isClientModal, clientProfileData, documentType }) => {
  // this component should really be changed to where the 'onSubmits' are passed in as props, rather than being defined within the component itself
  const [userData, setUserData] = useState([]); // to store selected user (displayed user)
  const [selectedUser, setSelectedUser] = useState(''); // selected user (dropdown user)
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const { userCompany, userId, userName } = useUserStore();
  const { setUsersToShareWith } = useFormBuilderStore();
  const { selectedUniqueId } = useClientStore();
  const [options, setOptions] = useState([]);

  const { socket } = useSocket();
  const navigate = useNavigate();
  const assignedDate = new Date().toISOString();

  // to display user right after select user from dropdown
  useEffect(() => {
    if (selectedUser) {
      handleClickAdd();
    }
  }, [selectedUser]);

  useEffect(() => {
    const retrieveCoworkers = async () => {
      setIsLoading(true);
      try {
        const serverClient = new ServerClient('/api/retrieveCoworkers');
        const res = await serverClient.post({ companyId: userCompany, userId: userId });
        setOptions(res?.data?.data);
      } catch (err) {
        console.log('error retrieving coworkers: ', err);
        setIsError(true);
      } finally {
        setIsLoading(false);
      }
    };
    retrieveCoworkers();
  }, []);

  // for when you click on the 'share' in the dropdown in createTemplatesInitial page
  const handleCreateTemplateOnSubmit = async () => {
    const userEmails = [];
    userData.forEach((item) => userEmails.push(item?.email));
    const type = 'shareTemplates';

    try {
      const serverClient = new ServerClient('/api/db/shareTemplate');
      const res = await serverClient.post({ templateId: templateId, userEmailsArray: userEmails });
      if (res?.data?.unsuccessfulAdds?.length > 0) {
        alert(`Could not share template with ${res?.data?.unsuccessfulAdds}`);
      }
      // notifications
      userData.forEach((user) => {
        const notification = {
          receiverId: user.userId,
          senderName: userName,
          uniqueId: selectedUniqueId,
          assignedDate: assignedDate,
          type: type,
        };
        // console.log('socket', socket);
        socket.emit('shareTemplates', notification);
      });
    } catch (err) {
      console.log('error found when closing shareTeam modal: ', err);
    } finally {
      onClose();
    }
  };

  // for when you are choosing the share with teammembers before you create the template itself
  //  these users will be added once the form itself is submitted and the id is returned, see the FormBuilder.js submission for furhte details
  const handleFormBuilderCreationOnSubmit = () => {
    if (userData?.length < 1) {
      // when we have no users to share with
      navigate('/Hub/CreateTemplatesInitial/Form-Builder');
    } else {
      // when we have users we want to share with
      const userEmails = [];
      userData.forEach((item) => userEmails.push(item?.email));
      setUsersToShareWith(userEmails);
      navigate('/Hub/CreateTemplatesInitial/Form-Builder');
    }
  };

  const handleClientProfileSubmit = async () => {
    // console.log('client profile data is: ', clientProfileData);
    const userEmails = [];
    userData.forEach((item) => userEmails.push(item?.email));
    if (userEmails?.length === 0) {
      return;
    }
    try {
      const serverClient = new ServerClient('/api/db/shareClientInfo');
      const res = await serverClient.post({ emails: userEmails, ...clientProfileData });
      // console.log('res is: ', res);
      
      // notifications
      userData.forEach((user) => {
        // console.log('userData', userData);
        const notification = {
          receiverId: user.userId,
          senderName: userName,
          uniqueId: selectedUniqueId,
          assignedDate: assignedDate,
          type: documentType,
        };
        // console.log('socket', socket);
        socket.emit('shareTemplates', notification);
      });
    } catch (err) {
      console.log('err sharing client info: ', err);
    } finally {
      onClose();
    }
  };

  // console.log('documentType', documentType);

  // add user
  const handleClickAdd = () => {
    if (selectedUser) {
      setUserData([...userData, selectedUser]);
      // console.log(userData);
      // console.log(selectedUser);
      setSelectedUser('');
      setOptions(options.filter((option) => option.email !== selectedUser.email));
    }
  };

  // remove user
  // * * * prop name set to rating due to rules in react-select
  const handleClickRemove = (id) => {
    const removedUser = userData.find((user) => user.id === id);
    setUserData(userData.filter((user) => user.id !== id));
    setOptions([
      ...options,
      {
        email: removedUser.email,
        name: removedUser.name,
        avatarLink: removedUser.avatarLink,
      },
    ]);
  };

  return (
    <>
      {/* title */}
      <div className="balance-create-popup-title-container">
        <h2 className="balance-create-popup-title balance-create-popup-title-center">Share with your team</h2>
        <div className="balance-create-popup-user-list-container">
          {/* user list */}
          {userData.map((item) => (
            <div key={item.id} className="balance-create-popup-user-container">
              <div className="balance-create-popup-user-inner-container">
                <div className="balance-create-popup-user-icon-container">
                  <img src={item.avatarLink || User1} alt={'user profile pic'} />
                </div>
                <div className="balance-create-popup-user-info-container">
                  <p className="balance-create-popup-user-name">{item?.name || 'User Error'}</p>
                  <p className="balance-create-popup-user-email">{item?.email || 'Unknown Email'}</p>
                </div>
              </div>
              <Button buttonName="Remove" className="balance-create-popup-remove-button" onClick={() => handleClickRemove(item.id)} />
            </div>
          ))}
          {/* team member dropdown */}
          <div className="balance-create-popup-dropdown-container">
            <label htmlFor="member" className="balance-create-popup-dropdown-label">
              Team member
            </label>
            {!isLoading ? (
              <Select
                id="member"
                value={selectedUser}
                onChange={(value) => {
                  setSelectedUser({ id: nanoid(), ...value });
                }}
                options={options}
                getOptionLabel={(option) => option.name}
                getOptionValue={(option) => option.email}
                maxMenuHeight={250}
                placeholder="Select team member"
                className="balance-create-popup-selection member-dropdown"
              />
            ) : (
              <span>Loading...</span>
            )}
          </div>
          {/* skip, done button */}
          {!isDocumentModal && (
            <div className="balance-create-popup-user-button-container">
              <Button buttonName="Skip" className="balance-create-popup-skip-button balance-global-movable-button" onClick={() => navigate('/Hub/CreateTemplatesInitial/Form-Builder')} />
              <Button buttonName="Done" className="balance-global-blue-button balance-global-movable-button" onClick={handleFormBuilderCreationOnSubmit} />
            </div>
          )}
          {isDocumentModal && (
            <div className="balance-create-popup-user-button-container">
              <Button
                buttonName="Cancel"
                className="balance-create-popup-skip-button balance-global-movable-button"
                // onClick={onClose}
                onClick={onClose}
              />
              {!isClientModal && (
                <Button
                  buttonName="Done"
                  className="balance-global-blue-button balance-global-movable-button"
                  // onClick={onClose}
                  onClick={handleCreateTemplateOnSubmit}
                />
              )}
              {isClientModal && (
                <Button
                  buttonName="Done"
                  className="balance-global-blue-button balance-global-movable-button"
                  // onClick={onClose}
                  onClick={handleClientProfileSubmit}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ShareTeam;
