import { Routes, Route } from 'react-router-dom';
import { SocketProvider } from './SocketContext';
import useStore from './store/useStore';
// components
// import Reporting from './pages/Hub/Reporting';
// import Support from './pages/Hub/Support';

// templates
import CreateTemplatesInitial from './pages/Hub/Templates/CreateTemplates/CreateTemplatesInitial';
import CreateTemplates from './pages/Hub/Templates/CreateTemplates/CreateTemplates';
import FormBuilder from './pages/Hub/Templates/FormBuilder/FormBuilder';
import Preview from './pages/Hub/Templates/FormBuilder/pages/Preview';
// clients
import Clients from './pages/Hub/Clients/Clients';
import ClientsProfile from './pages/Hub/Clients/ClientsProfile';
// tasks
import Tasks from './pages/Hub/Tasks/Tasks';
import AssignTask from './pages/Hub/Tasks/AssignTask';
import ViewTask from './pages/Hub/Tasks/ViewTask';
// dashboard
import Dashboard from './pages/Hub/Dashboard/Dashboard';
// notifications
import Notifications from './pages/Hub//Notifications/Notifications';
// settings
import Settings from './pages/Hub/Settings/Settings';
import UserPermissions from './pages/Hub/Settings/UserPermissions';
import UserAccess from './pages/Hub/Settings/UserAccess';
import Support from './pages/Hub/Settings/Support';
import SettingsNotifications from './pages/Hub/Settings/SettingsNotifications';
import Account from './pages/Hub/Settings/Account';
import Integrations from './pages/Hub/Settings/Integrations';
import UserProfile from './pages/Hub/Settings/UserProfile';
import PaymentMethods from './pages/Hub/Settings/PaymentMethods/PaymentMethods';
import InviteUsers from './pages/Hub/Settings/InviteUsers';
import AccountDeactivation from './pages/Hub/Settings/AccountDeactivation';
// nav for template page
import Nav from './components/Nav/TemplateNav';
// others
import ShareTab from './pages/Hub/Templates/FormBuilder/pages/ShareTab';

const TemplateRoutes = () => {
  const { openNav } = useStore();
  // able to scroll with sidebar
  const mainStyle = {
    width: '100%',
    marginLeft: openNav ? '330px' : '65px',
  };

  return (
    <SocketProvider>
      <div style={{ display: 'flex' }}>
        <Nav />
        <main style={mainStyle}>
          <Routes>
            {/* dashboard */}
            <Route index element={<Dashboard />} />
            <Route path="/Hub/Dashboard" element={<Dashboard />} />
            {/* templates */}
            <Route path="/Hub/CreateTemplatesInitial" element={<CreateTemplatesInitial />} />
            {/* <Route path='/Hub/CreateTemplates' element={<CreateTemplates />} /> */}
            <Route path="/Hub/CreateTemplatesInitial/Form-Builder" element={<FormBuilder />} />
            <Route path="/Hub/CreateTemplatesInitial/Preview" element={<Preview />} />
            {/* notifications */}
            <Route path="/Hub/Notifications" element={<Notifications />} />
            {/* tasks */}
            <Route path="/Hub/Tasks" element={<Tasks />} />
            <Route path="/Hub/Tasks/Assign-Task" element={<AssignTask />} />
            <Route path="/Hub/Tasks/View" element={<ViewTask />} />
            {/* clients page */}
            <Route path="/Hub/Clients" element={<Clients />} />
            <Route path="/Hub/Clients/ClientsProfile" element={<ClientsProfile />} />
            {/* settings page */}
            <Route path="/Hub/Settings" element={<Settings />} />
            <Route path="/Hub/Settings/Support" element={<Support />} />
            <Route path="/Hub/Settings/Notifications" element={<SettingsNotifications />} />
            <Route path="/Hub/Settings/Account" element={<Account />} />
            <Route path="/Hub/Settings/Integrations" element={<Integrations />} />
            <Route path="/Hub/Settings/User-Profile" element={<UserProfile />} />
            <Route path="/Hub/Settings/User-Permissions" element={<UserPermissions />} />
            <Route path="/Hub/Settings/User-Permissions/User-Access" element={<UserAccess />} />
            <Route path="/Hub/Settings/PaymentMethods" element={<PaymentMethods />} />
            <Route path="/Hub/Settings/InviteUsers" element={<InviteUsers />} />
            <Route path="/Hub/Settings/AccountDeactivation" element={<AccountDeactivation />} />
            {/* others */}
            <Route path="/Share" element={<ShareTab />} />
            {/* <Route path='/Hub/Reporting' element={<Reporting />} />
          <Route path='/Hub/Support' element={<Support />} /> */}
          </Routes>
        </main>
      </div>
    </SocketProvider>
  );
};

export default TemplateRoutes;
