import './ThankYouPage.css';

const ThankYouPage = ({ forClientPortal, message }) => {
  return (
    <div className="balance-thank-you-page-container">
      <div className="balance-thank-you-page-main">
        <h1>Thank you!</h1>
        {forClientPortal ? (
          <p className="balance-thank-you-page-description">
            Your application was successfully submitted.
            <br />
            Please exit this window.
          </p>
        ) : (
          <p className="balance-thank-you-page-description">{message}</p>
        )}
      </div>
    </div>
  );
};

export default ThankYouPage;
