import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import { Icon } from '@iconify/react';
import { useEmailNotificationsEnabled } from '../../../hooks/useNotificationHooks';
import useUserStore from '../../../store/useUserStore';
import Toggle from '../../../components/Button/Toggle';
import './SettingsNotifications.css';
import './Settings.css';

const SettingsNotifications = () => {
  const toggleEmailsData = [
    {
      id: nanoid(),
      toggleTitle: 'News and updates',
      toggleDesc: 'News about product and feature updates.',
    },
  ];

  // const togglePushData = [
  //   {
  //     id: nanoid(),
  //     toggleTitle: 'Reminder',
  //     toggleDesc: 'To remind you of updates that you might have missed.',
  //   },
  // ];

  const [isNewsUpdatesOn, setNewsUpdatesOn] = useState(false);
  const { userId } = useUserStore();
  const { mutate: emailNotificationEnabledMutate } = useEmailNotificationsEnabled();

  const navigate = useNavigate();

  // toggle
  const onToggleEmailNotificationEnabled = (e) => {
    const toggleUpdate = e.target.checked;
    setNewsUpdatesOn(toggleUpdate);

    emailNotificationEnabledMutate(
      { userId, toggleUpdate },
      {
        onSuccess: () => {
          console.log(`Email notification ${toggleUpdate ? 'enabled' : 'disabled'}.`);
        },
        onError: () => {
          console.log('Error toggle email notification.');
        },
      }
    );
  };

  return (
    <div className="notifications-settings-container balance-settings-container">
      <div className="balance-settings-back-button-container" onClick={() => navigate('/Hub/Settings')}>
        <Icon icon="ic:sharp-arrow-back-ios" className="balance-settings-back-button" />
      </div>
      <div className="notifications-settings-title-container">
        <h1 className="balance-settings-title">Notifications</h1>
        <p className="balance-settings-description">Manage your notifications settings.</p>
        {/* line */}
        <div className="balance-settings-line"></div>
      </div>
      {/* contents */}
      <div className="notifications-settings-contents-container">
        <div className="notifications-settings-contents-emails-container">
          <div className="notifiacations-settings-contents-emails-left-container">
            <h1 className="notifications-settings-contents-emails-title">Email Notifications</h1>
            <p className="notifications-settings-contents-emails-desc">
              Get emails to find out what's going on when you're not online. You can turn these off.
            </p>
          </div>
          <div className="notifications-settings-contents-emails-right-container">
            {toggleEmailsData.map((data, index) => (
              <div className="notifications-settings-contents-emails-toggle-contianer">
                <Toggle onChange={onToggleEmailNotificationEnabled} state={isNewsUpdatesOn} />
                <div className="notifications-settings-contents-emails-toggle-inner-contianer">
                  <h3 className="notifications-settings-contents-emails-toggle-title">{data.toggleTitle}</h3>
                  <p className="notifications-settings-contents-emails-toggle-desc">{data.toggleDesc}</p>
                </div>
              </div>
            ))}
            {/* dummy */}
            <div className="notifications-settings-contents-emails-toggle-contianer">
              <Toggle />
              <div className="notifications-settings-contents-emails-toggle-inner-contianer">
                <h3 className="notifications-settings-contents-emails-toggle-title">Reminder</h3>
                <p className="notifications-settings-contents-emails-toggle-desc">
                  To remind you of updates that you might have missed.
                </p>
              </div>
            </div>
            <div className="notifications-settings-contents-emails-toggle-contianer">
              <Toggle />
              <div className="notifications-settings-contents-emails-toggle-inner-contianer">
                <h3 className="notifications-settings-contents-emails-toggle-title">Comments</h3>
                <p className="notifications-settings-contents-emails-toggle-desc">Comments on your application.</p>
              </div>
            </div>
          </div>
        </div>
        {/* line */}
        <div className="balance-settings-line"></div>
        <div className="notifications-settings-contents-emails-container">
          <div className="notifiacations-settings-contents-emails-left-container">
            <h1 className="notifications-settings-contents-emails-title">Push Notifications</h1>
            <p className="notifications-settings-contents-emails-desc">Get push notifications.</p>
          </div>
          <div className="notifications-settings-contents-emails-right-container">
            {/* {togglePushData.map((data, index) => (
              <div className="notifications-settings-contents-emails-toggle-contianer">
                <Toggle onChange={onToggleEmailNotificationEnabled} state={isToggleOn} />
                <div className="notifications-settings-contents-emails-toggle-inner-contianer">
                  <h3 className="notifications-settings-contents-emails-toggle-title">{data.toggleTitle}</h3>
                  <p className="notifications-settings-contents-emails-toggle-desc">{data.toggleDesc}</p>
                </div>
              </div>
            ))} */}
            {/* dummy */}
            <div className="notifications-settings-contents-emails-toggle-contianer">
              <Toggle />
              <div className="notifications-settings-contents-emails-toggle-inner-contianer">
                <h3 className="notifications-settings-contents-emails-toggle-title">Reminder</h3>
                <p className="notifications-settings-contents-emails-toggle-desc">
                  To remind you of updates that you might have missed.
                </p>
              </div>
            </div>
            <div className="notifications-settings-contents-emails-toggle-contianer">
              <Toggle />
              <div className="notifications-settings-contents-emails-toggle-inner-contianer">
                <h3 className="notifications-settings-contents-emails-toggle-title">Comments</h3>
                <p className="notifications-settings-contents-emails-toggle-desc">Comments on your application.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsNotifications;
