import Button from '../../components/Button/Button';
import './PopupGlobal.css';

const SubmitFormPopup = ({ onClose, onConfirm, checkConfirm, inputOnChange, inputError, clientName, companyName }) => {
  // close modal
  const handleClickCloseModal = () => {
    if (onClose) {
      onClose();
    }
  };

  return (
    <div className="balance-confirm-popup-container">
      <p className="balance-confirm-popup-message">{checkConfirm}</p>
      <div className="balance-submit-form-popup-input-container">
        <input
          type="text"
          name='clientName'
          value={clientName}
          className={`balance-submit-form-popup-input long-text-style ${inputError ? 'balance-submit-form-popup-input-error' : undefined}`}
          placeholder="Full Name"
          onChange={inputOnChange}
        />
        {inputError && <p style={{ color: 'red' }}>Please enter your full name to continue.</p>}
        <input
          type="text"
          name='companyName'
          value={companyName}
          className={`balance-submit-form-popup-input long-text-style ${inputError ? 'balance-submit-form-popup-input-error' : undefined}`}
          placeholder="Company Name"
          onChange={inputOnChange}
        />
        {inputError && <p style={{ color: 'red' }}>Please enter your company name to continue.</p>}
      </div>
      <div className="balance-confirm-popup-button-container">
        <Button buttonName="Return" onClick={handleClickCloseModal} className="balance-confirm-popup-cancel-button" />
        <Button buttonName="Confirm" onClick={onConfirm} className="balance-confirm-popup-confirm-button" />
      </div>
    </div>
  );
};

export default SubmitFormPopup;
