import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';

const SignUpPage1 = ({ invalidEmail, usedEmail, excludedEmail, isLoading, setInvalidEmail, handleChangeEmail, handleNextButtonClick }) => {
  const navigate = useNavigate();

  const buttonText = isLoading ? 'Loading...' : 'Next';

  return (
    <div className="balance-sign-up-left-container">
      <div className="balance-global-form balance-signup-input-container">
        <div className="balance-sign-up-title-container">
          <h1 className="balance-sign-up-title">Sign up</h1>
          <p className="balance-sign-up-sub-title">Simplify your banking experience.</p>
        </div>
        <>
          <label htmlFor="email" className="balance-global-label">
            <div className="balance-label-left-side">Work email *</div>
            {invalidEmail && <p className="balance-label-error-info">{invalidEmail}</p>}
            {usedEmail && <p className="balance-label-error-info">{usedEmail}</p>}
            {excludedEmail && <p className="balance-label-error-info">{excludedEmail}</p>}
          </label>
          <input
            type="text"
            id="email"
            placeholder="Email"
            className={`balance-global-input ${invalidEmail ? 'balance-global-input-error' : ''}`}
            onChange={handleChangeEmail}
            onBlur={() => setInvalidEmail('')}
          />
        </>
        <Button
          className={`balance-global-blue-button balance-global-movable-button ${isLoading ? 'balance-global-disabled-button' : undefined}`}
          buttonName={buttonText}
          iconPosition={'after'}
          onClick={handleNextButtonClick}
        />
        <div className="balance-sign-up-container">
          <p className="balance-global-gray-question">Already have an account?</p>
          <Button onClick={() => navigate('/Login')} buttonName="Log in" className="balance-global-blue-navigate-button" />
        </div>
      </div>
    </div>
  );
};

export default SignUpPage1;
