import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { nanoid } from 'nanoid';
import useUserStore from '../../../store/useUserStore';
import useClientStore from '../../../store/useClientStore';
import useTaskStore from '../../../store/useTaskStore';
// hooks
import { useSocket } from '../../../SocketContext';
import { useChangeStatus } from '../../../hooks/useClientHooks';
import { useUserTaskPermissionsData } from '../../../hooks/usePermissionHooks';
import {
  useGetAssignedTasks,
  useGetMyTasks,
  useDeleteTasks,
  useDeleteAssignedTasks,
  useArchiveTasks,
  useRetrieveArchivedTasks,
  useDeleteArchivedTasks,
} from '../../../hooks/useTaskHooks';
// components
import PageTopLayout from '../../../components/Layout/PageTopLayout';
import Button from '../../../components/Button/Button';
import Dropdown from '../../../components/Dropdown/Dropdown';
import useDropdown from '../../../hooks/useDropdown';
import Loading from '../../../components/Loading/Loading';
import Error from '../../../components/Loading/Error';
import Modal from '../../../components/Popup/Modal';
import ChangeStatusPopup from '../../../components/Popup/ChangeStatusPopup';
import DeletePopup from '../../../components/Popup/DeletePopup';
import ArchivePopup from '../../../components/Popup/ArchivePopup';
// css
import '../../../components/Grid/Grid.css';
// svg
import DotIcon from '../../../assets/Icon/3Dot_Button_Icon.svg';

const Tasks = () => {
  const layout = [
    {
      id: nanoid(),
      title: 'Task Workflow Manager',
    },
  ];

  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermAssigned, setSearchTermAssigned] = useState('');
  const [searchTermArchived, setSearchTermArchived] = useState('');
  const [activeStatusTab, setActiveStatusTab] = useState('My Tasks');
  const [isDeletePopupOpen, setDeletePopupOpen] = useState(false); // delete popup
  const [isArchivePopupOpen, setArchivePopupOpen] = useState(false); // archive popup
  const [deleteWhichTask, setDeleteWhichTask] = useState(null); // delete action when clicked on delete
  const [archiveWhichTask, setArchiveWhichTask] = useState(null); // archive action
  const [filteredData, setFilteredData] = useState([]); // filter to search
  const [isChangeStatusOpen, setChangeStatusOpen] = useState(false);
  // store
  const { selectedUniqueId, setSelectedUniqueId } = useClientStore();
  const { userName, taskData, setTaskData, setTaskPermissions } = useUserStore(); // data
  const { selectedStatusTab, setSelectedStatusTab, selectedTaskId, setSelectedTaskId } = useTaskStore();
  // hooks
  const { openDropdown, handleClickDot, closeDropdown, handleClickOutside, dropdownRef } = useDropdown();
  const { data: assignedData, isLoading: assignedDataLoading, isError: assignedDataError } = useGetAssignedTasks();
  const { data: myTasksData } = useGetMyTasks();
  const { data: userTasksData } = useUserTaskPermissionsData(); // user permissions
  const { mutate: deleteTasksMutate } = useDeleteTasks(); // delete tasks
  const { mutate: deleteAssignedTasksMutate } = useDeleteAssignedTasks(); // delete tasks
  const { mutate: archiveTasksMutate } = useArchiveTasks(); // archive tasks
  const { data: archivedData } = useRetrieveArchivedTasks();
  const { mutate: deleteArchivedTasksMutate } = useDeleteArchivedTasks(); // delete archived tasks
  const { mutate: changeStatusMutate } = useChangeStatus();
  const { socket } = useSocket();

  const navigate = useNavigate();
  const location = useLocation();

  // need this for change status
  useEffect(() => {
    if (selectedStatusTab === 'My Tasks') {
      setFilteredData(myTasksData);
      setTaskData(myTasksData);
    } else if (selectedStatusTab === 'Assigned') {
      setFilteredData(assignedData);
      setTaskData(assignedData);
    } else if (selectedStatusTab === 'Archived') {
      setFilteredData(archivedData);
      setTaskData(archivedData);
    }
  }, [selectedStatusTab, myTasksData, assignedData, archivedData, setTaskData]);

  // permissions load
  useEffect(() => {
    if (userTasksData) {
      const { canEditTasks, canDeleteTasks, canShareTasks } = userTasksData;
      setTaskPermissions({
        canEdit: canEditTasks,
        canDelete: canDeleteTasks,
        canShare: canShareTasks,
      });
    }
  }, [userTasksData, setTaskPermissions]);

  // make the # link work
  useEffect(() => {
    const hash = location.hash.substring(1);
    if (hash === 'Assigned') {
      setSelectedStatusTab('Assigned');
      setActiveStatusTab('Assigned');
      if (assignedData) {
        setFilteredData(assignedData);
      }
    } else if (hash === 'Archived') {
      setSelectedStatusTab('Archived');
      setActiveStatusTab('Archived');
      if (archivedData) {
        setFilteredData(archivedData);
      }
    } else {
      setSelectedStatusTab('My Tasks');
      setActiveStatusTab('My Tasks');
      if (myTasksData) {
        setFilteredData(myTasksData);
      }
    }
    setSearchTerm('');
    setSearchTermAssigned('');
    setSearchTermArchived('');
  }, [location.hash, assignedData, myTasksData, archivedData]);

  // status change
  const getTypeStyle = (status) => {
    switch (status) {
      case 'Complete':
        return 'status-1';
      case 'In-progress':
        return 'status-2';
      case 'Incomplete':
        return 'status-3';
      default:
        return '';
    }
  };
  const handleClickStatus = (uniqueId) => {
    // console.log(uniqueId);
    setSelectedUniqueId(uniqueId);
    setChangeStatusOpen(true);
  };
  // status change action
  const handleChangeStatus = (status) => {
    const assignedDate = new Date().toISOString();

    const newStatus = {
      uniqueId: selectedUniqueId,
      applicationStatus: status,
    };

    changeStatusMutate(newStatus, {
      onSuccess: () => {
        console.log('Status change action done.');

        const task = taskData?.find((task) => task.uniqueId === selectedUniqueId);
        // console.log('task', task);
        // console.log('assignedToId', task?.assignedToId);
        if (!task) {
          console.error('Task not found.');
          return;
        }

        // receiverId is different in my tasks tab and assigned tab
        let receiverId;
        if (selectedStatusTab === 'Assigned') {
          receiverId = task?.assignedToId;
        } else {
          receiverId = task?.userId;
        }
        console.log('receiverId', receiverId);

        const statusChangeNotification = {
          receiverId: receiverId,
          senderName: userName,
          uniqueId: selectedUniqueId,
          applicationStatus: status,
          assignedDate: assignedDate,
          message: `${userName} changed a status in your application.`,
        };
        console.log('socket', socket);
        socket.emit('statusChange', statusChangeNotification);
      },
      onError: (error) => {
        console.error('Error changing status:', error);
      },
    });
  };
  // close
  const closeChangeStatus = () => {
    setChangeStatusOpen(false);
  };

  // filter search
  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    // console.log('Typing keyword:', keyword);
    if (selectedStatusTab === 'My Tasks') {
      setSearchTerm(keyword);
      const filteredTasks = keyword
        ? myTasksData.filter(
            (item) =>
              (item.client && item.client.toLowerCase().includes(keyword)) ||
              (item.userName && item.userName.toLowerCase().includes(keyword))
          )
        : myTasksData;
      setFilteredData(filteredTasks);
    }
    if (selectedStatusTab === 'Assigned') {
      setSearchTermAssigned(keyword);
      const filteredAssigned = keyword
        ? assignedData.filter((item) => item.client && item.client.toLowerCase().includes(keyword))
        : assignedData;
      // console.log('Filtered Assigned:', filteredAssigned);
      setFilteredData(filteredAssigned);
    }
    if (selectedStatusTab === 'Archived') {
      setSearchTermArchived(keyword);
      const filteredAssigned = keyword
        ? archivedData.filter(
            (item) =>
              (item.client && item.client.toLowerCase().includes(keyword)) ||
              (item.userName && item.assignedTo.toLowerCase().includes(keyword))
          )
        : archivedData;
      setFilteredData(filteredAssigned);
    }
  };

  // loading, error screens
  if (assignedDataLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  if (assignedDataError) {
    return (
      <div>
        <Error />
      </div>
    );
  }

  //  * * * open * * *
  const handleClickOpen = (uuid) => {
    setSelectedTaskId(uuid);
    console.log('Which uuid?', uuid);
    navigate('/Hub/Tasks/View');
  };

  // * * * delete * * *
  const handleClickDelete = (uuid) => {
    setDeleteWhichTask(uuid);
    // console.log('Which uuid?', uuid);
    setDeletePopupOpen(true);
  };
  // delete action
  const handleDeleteTasks = async () => {
    try {
      if (selectedStatusTab === 'My Tasks') {
        await deleteTasksMutate({ uuid: deleteWhichTask });
      } else if (selectedStatusTab === 'Assigned') {
        await deleteAssignedTasksMutate({ uuid: deleteWhichTask });
      } else if (selectedStatusTab === 'Archived') {
        await deleteArchivedTasksMutate({ uuid: deleteWhichTask });
      }
      setFilteredData((prev) => prev.filter((task) => task.uniqueId !== deleteWhichTask));
      closeDeletePopup();
    } catch (error) {
      console.error('Error deleting task.', error);
    }
  };
  // close delete popup
  const closeDeletePopup = () => {
    setDeletePopupOpen(false);
  };

  // * * * archive * * *
  const handleClickArchive = (uuid) => {
    setArchiveWhichTask(uuid);
    // console.log('Which uuid?', uuid);
    setArchivePopupOpen(true);
  };
  const handleArchiveTasks = () => {
    const uuid = archiveWhichTask;
    if (!uuid) {
      console.error('uuid not found.');
      return;
    }
    // console.log('Archive uuid:', uuid);
    archiveTasksMutate(
      { uuid },
      {
        onSuccess: () => {
          setArchivePopupOpen(false);
          navigate('/Hub/Tasks#MyTasks');
        },
        onError: (error) => {
          console.error('Error archiving task:', error);
        },
      }
    );
  };
  const closeArchivePopup = () => {
    setArchivePopupOpen(false);
  };

  return (
    <div>
      <PageTopLayout
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        searchTermAssigned={searchTermAssigned}
        searchTermArchived={searchTermArchived}
        layout={layout}
        isTasksPage={true}
        selectedStatusTab={selectedStatusTab}
        setSelectedStatusTab={setSelectedStatusTab}
        activeStatusTab={activeStatusTab}
        setActiveStatusTab={setActiveStatusTab}
      />
      {activeStatusTab === 'My Tasks' && (
        <div onClick={handleClickOutside} className="balance-clients-container">
          {/* grid header */}
          <div className="balance-grid-clients-title-header">
            <p>Tasks</p>
          </div>
          {/* grid table */}
          <div className="balance-grid-tasks-table">
            <div className="balance-grid-clients-header">Assigned By</div>
            {/* <div className="balance-grid-clients-header">Task</div> */}
            <div className="balance-grid-clients-header">Client Name</div>
            <div className="balance-grid-clients-header">Task Status</div>
            <div className="balance-grid-clients-header">Application type</div>
            <div className="balance-grid-clients-header">Date Assigned</div>
            <div className="balance-grid-clients-header"></div>
            {/* grid record */}
            {filteredData?.length > 0 &&
              filteredData.map((item, index) => {
                const dropdownButtons = [
                  {
                    label: 'Open',
                    onClick: () => handleClickOpen(item.uniqueId),
                  },
                  {
                    label: 'Change Status',
                    onClick: () => handleClickStatus(item.uniqueId),
                  },
                  {
                    label: 'Archive',
                    onClick: () => handleClickArchive(item.uniqueId),
                  },
                  {
                    label: 'Delete',
                    onClick: () => handleClickDelete(item?.uniqueId),
                  },
                ];
                return (
                  <React.Fragment key={index}>
                    <div className="balance-grid-cell balance-grid-title">
                      <input type="checkbox" className="balance-grid-checkbox" />
                      <div className="balance-grid-client-name-container">
                        <p className="balance-grid-title balance-grid-hover-title">{item.userName}</p>
                      </div>
                    </div>
                    {/* <div className="balance-grid-cell balance-grid-type">
                      <span>{item.task}</span>
                    </div> */}
                    <div className="balance-grid-cell balance-grid-tasks-client">
                      <span className="balance-grid-application-name">{item.client}</span>
                    </div>
                    <div className="balance-grid-cell balance-grid-used">
                      <span className={`${getTypeStyle(item.status)}`}>{item.status}</span>
                    </div>
                    <div className="balance-grid-cell balance-grid-clients-type">
                      <span>{item.templateType}</span>
                    </div>
                    <div className="balance-grid-cell balance-grid-clients-date">
                      <span>{new Date(item.assignedDate).toLocaleDateString()}</span>
                    </div>
                    <div className="balance-grid-cell">
                      <Button
                        icon={DotIcon}
                        iconPosition="after"
                        className="balance-grid-dot-button"
                        onClick={(e) => handleClickDot(item.uniqueId, e)}
                      />
                      {/* dropdown */}
                      {openDropdown[item.uniqueId] && (
                        <Dropdown
                          item={openDropdown[item.uniqueId]}
                          openDropdown={openDropdown}
                          dropdownRef={dropdownRef}
                          dropdownButtons={dropdownButtons}
                          isTasksPage={true}
                        />
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            {filteredData?.length === 0 && (
              <div
                style={{
                  display: 'grid',
                  gridColumn: 'span 5',
                  placeItems: 'center',
                  color: 'gray',
                  fontStyle: 'italic',
                  padding: '1.5rem',
                }}
              >
                No tasks to display
              </div>
            )}
          </div>
          {/* grid footer */}
          <div className="balance-grid-footer">
            <div className="balance-grid-footer-pages">Page 1 of 1</div>
            <div className="balance-grid-footer-button">
              <Button buttonName="Previous" className="balance-grid-footer-previous-button" />
              <Button buttonName="Next" className="balance-grid-footer-next-button" />
            </div>
          </div>
        </div>
      )}
      {activeStatusTab === 'Assigned' && (
        <div onClick={handleClickOutside} className="balance-clients-container">
          {/* grid header */}
          <div className="balance-grid-clients-title-header">
            <p>Tasks</p>
          </div>
          {/* grid table */}
          <div className="balance-grid-tasks-table">
            <div className="balance-grid-clients-header">Assigned To</div>
            {/* <div className="balance-grid-clients-header">Task</div> */}
            <div className="balance-grid-clients-header">Client Name</div>
            <div className="balance-grid-clients-header">Task Status</div>
            <div className="balance-grid-clients-header">Application type</div>
            <div className="balance-grid-clients-header">Date Assigned</div>
            <div className="balance-grid-clients-header"></div>
            {/* grid record */}
            {filteredData?.length > 0 &&
              filteredData.map((item) => {
                const isCreatedByCurrentUser = item.userName === userName;
                const dropdownButtons = [
                  {
                    label: 'Open',
                    onClick: () => handleClickOpen(item.uniqueId),
                  },
                  {
                    label: 'Change Status',
                    onClick: () => handleClickStatus(item.uniqueId),
                  },
                  {
                    label: 'Archive',
                    onClick: () => handleClickArchive(item.uniqueId),
                  },
                  {
                    label: 'Delete',
                    onClick: () => handleClickDelete(item?.uniqueId),
                  },
                ];
                return (
                  <React.Fragment key={item.uniqueId}>
                    <div className="balance-grid-cell balance-grid-title">
                      <input type="checkbox" className="balance-grid-checkbox" />
                      <div className="balance-grid-client-name-container">
                        <p className="balance-grid-title balance-grid-hover-title">{item.assignedTo}</p>
                      </div>
                    </div>
                    {/* <div className="balance-grid-cell balance-grid-type">
                      <span>{item.task}</span>
                    </div> */}
                    <div className="balance-grid-cell balance-grid-tasks-client">
                      <span className="balance-grid-application-name">{item.client}</span>
                    </div>
                    <div className="balance-grid-cell balance-grid-used">
                      <span className={`${getTypeStyle(item.status)}`}>{item.status}</span>
                    </div>
                    <div className="balance-grid-cell balance-grid-clients-type">
                      <span>{item.templateType}</span>
                    </div>
                    <div className="balance-grid-cell balance-grid-clients-date">
                      <span>{new Date(item.assignedDate).toLocaleDateString()}</span>
                    </div>
                    <div className="balance-grid-cell">
                      <Button
                        icon={DotIcon}
                        iconPosition="after"
                        className="balance-grid-dot-button"
                        onClick={(e) => handleClickDot(item.uniqueId, e)}
                      />
                      {/* dropdown */}
                      {openDropdown[item.uniqueId] && (
                        <Dropdown
                          item={openDropdown[item.uniqueId]}
                          openDropdown={openDropdown}
                          dropdownRef={dropdownRef}
                          dropdownButtons={dropdownButtons}
                          isCreatedByCurrentUser={isCreatedByCurrentUser}
                          isTasksPage={true}
                        />
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            {filteredData?.length === 0 && (
              <div
                style={{
                  display: 'grid',
                  gridColumn: 'span 5',
                  placeItems: 'center',
                  color: 'gray',
                  fontStyle: 'italic',
                  padding: '1.5rem',
                }}
              >
                No tasks to display
              </div>
            )}
          </div>
          {/* grid footer */}
          <div className="balance-grid-footer">
            <div className="balance-grid-footer-pages">Page 1 of 1</div>
            <div className="balance-grid-footer-button">
              <Button buttonName="Previous" className="balance-grid-footer-previous-button" />
              <Button buttonName="Next" className="balance-grid-footer-next-button" />
            </div>
          </div>
        </div>
      )}
      {activeStatusTab === 'Archived' && (
        <div onClick={handleClickOutside} className="balance-clients-container">
          {/* grid header */}
          <div className="balance-grid-clients-title-header">
            <p>Tasks</p>
          </div>
          {/* grid table */}
          <div className="balance-grid-tasks-table">
            <div className="balance-grid-clients-header">Assigned To</div>
            {/* <div className="balance-grid-clients-header">Task</div> */}
            <div className="balance-grid-clients-header">Client Name</div>
            <div className="balance-grid-clients-header">Task Status</div>
            <div className="balance-grid-clients-header">Application type</div>
            <div className="balance-grid-clients-header">Date Assigned</div>
            <div className="balance-grid-clients-header"></div>
            {/* grid record */}
            {filteredData?.length > 0 &&
              filteredData.map((item) => {
                const isCreatedByCurrentUser = item.userName === userName;
                const dropdownButtons = [
                  {
                    label: 'Open',
                    onClick: () => handleClickOpen(item.uniqueId),
                  },
                  {
                    label: 'Change Status',
                    onClick: () => handleClickStatus(item.uniqueId),
                  },
                  {
                    label: 'Delete',
                    onClick: () => handleClickDelete(item?.uniqueId),
                  },
                ];
                return (
                  <React.Fragment key={item.uniqueId}>
                    <div className="balance-grid-cell balance-grid-title">
                      <input type="checkbox" className="balance-grid-checkbox" />
                      <div className="balance-grid-client-name-container">
                        <p className="balance-grid-title balance-grid-hover-title">{item.assignedTo}</p>
                      </div>
                    </div>
                    {/* <div className="balance-grid-cell balance-grid-type">
                      <span>{item.task}</span>
                    </div> */}
                    <div className="balance-grid-cell balance-grid-tasks-client">
                      <span className="balance-grid-application-name">{item.client}</span>
                    </div>
                    <div className="balance-grid-cell balance-grid-used">
                      <span className={`${getTypeStyle(item.status)}`}>{item.status}</span>
                    </div>
                    <div className="balance-grid-cell balance-grid-clients-type">
                      <span>{item.templateType}</span>
                    </div>
                    <div className="balance-grid-cell balance-grid-clients-date">
                      <span>{new Date(item.assignedDate).toLocaleDateString()}</span>
                    </div>
                    <div className="balance-grid-cell">
                      <Button
                        icon={DotIcon}
                        iconPosition="after"
                        className="balance-grid-dot-button"
                        onClick={(e) => handleClickDot(item.uniqueId, e)}
                      />
                      {/* dropdown */}
                      {openDropdown[item.uniqueId] && (
                        <Dropdown
                          item={openDropdown[item.uniqueId]}
                          openDropdown={openDropdown}
                          dropdownRef={dropdownRef}
                          dropdownButtons={dropdownButtons}
                          isCreatedByCurrentUser={isCreatedByCurrentUser}
                          isTasksPage={true}
                        />
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
            {filteredData?.length === 0 && (
              <div
                style={{
                  display: 'grid',
                  gridColumn: 'span 5',
                  placeItems: 'center',
                  color: 'gray',
                  fontStyle: 'italic',
                  padding: '1.5rem',
                }}
              >
                No tasks to display
              </div>
            )}
          </div>
          {/* grid footer */}
          <div className="balance-grid-footer">
            <div className="balance-grid-footer-pages">Page 1 of 1</div>
            <div className="balance-grid-footer-button">
              <Button buttonName="Previous" className="balance-grid-footer-previous-button" />
              <Button buttonName="Next" className="balance-grid-footer-next-button" />
            </div>
          </div>
        </div>
      )}
      {isChangeStatusOpen && (
        <Modal width="36rem" onClose={closeChangeStatus}>
          <ChangeStatusPopup
            onClose={closeChangeStatus}
            handleChangeStatus={handleChangeStatus}
            closeDropdown={() => closeDropdown(selectedUniqueId)}
            isTaskPage={true}
          />
        </Modal>
      )}
      {isDeletePopupOpen && (
        <Modal width="32rem" onClose={closeDeletePopup}>
          <DeletePopup
            onClose={closeDeletePopup}
            buttonName="Delete"
            checkConfirm="Are you sure you want to delete?"
            onConfirm={handleDeleteTasks}
          />
        </Modal>
      )}
      {isArchivePopupOpen && (
        <Modal width="32rem" onClose={closeArchivePopup}>
          <ArchivePopup
            onClose={closeArchivePopup}
            checkConfirm="Are you sure you want to archive?"
            onConfirm={handleArchiveTasks}
          />
        </Modal>
      )}
    </div>
  );
};

export default Tasks;
