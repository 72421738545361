import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { nanoid } from 'nanoid';
import ServerClient from '../../../services/api-client';
import useUserStore from '../../../store/useUserStore';
import useClientStore from '../../../store/useClientStore';
import { useUserClientPermissionsData } from '../../../hooks/usePermissionHooks';
import PageTopLayout from '../../../components/Layout/PageTopLayout';
import Button from '../../../components/Button/Button';
import Loading from '../../../components/Loading/Loading';
import Error from '../../../components/Loading/Error';
import '../../../components/Grid/Grid.css';
import DotIcon from '../../../assets/Icon/3Dot_Button_Icon.svg';

const Clients = () => {
  const layout = [
    {
      id: nanoid(),
      title: 'Clients',
      description: 'View the latest updates on your clients',
    },
  ];

  const formatDate = (date) => {
    const dateObj = new Date(date);
    return dateObj.toString().slice(4, 15);
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [activeStatusTab, setActiveStatusTab] = useState('All');
  const [selectedStatusTab, setSelectedStatusTab] = useState('All');
  const [completedForms, setCompletedForms] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sharedClients, setSharedClients] = useState([]);
  const { userId } = useUserStore();
  const { setClientPermissions } = useUserStore();
  const { setClientData, setSharedClientData } = useClientStore();
  const { data: userClientData, isLoading: clientDataLoading, isError: clientDataError } = useUserClientPermissionsData(); // user permissions

  const navigate = useNavigate();

  // filter search
  const handleSearch = (e) => {
    const keyword = e.target.value;
    setSearchTerm(keyword);
    const filtered = completedForms.filter((item) => item?.formTitle.toLowerCase().includes(keyword.toLowerCase()) && (selectedStatusTab === 'All' || item?.status === selectedStatusTab));
    setFilteredData(filtered);
  };

  useEffect(() => {
    if (userClientData) {
      const { canEditClients, canDeleteClients, canShareClients } = userClientData;
      setClientPermissions({
        canEdit: canEditClients,
        canDelete: canDeleteClients,
        canShare: canShareClients,
      });
    }
  }, [userClientData, setClientPermissions]);

  // filter application status
  useEffect(() => {
    const filterData = () => {
      const filtered = completedForms.filter((item) => selectedStatusTab === 'All' || item?.applicationStatus === selectedStatusTab);
      setFilteredData(filtered);
    };
    filterData();
  }, [selectedStatusTab]);

  useEffect(() => {
    const getCompletedForms = async () => {
      setIsLoading(true);
      try {
        const serverClient = new ServerClient('/api/getClientHubForms');
        const res = await serverClient.post({ userId: userId });
        setCompletedForms(res.data.completedForms);
        setFilteredData(res.data.completedForms);
        setSharedClientData(res.data.sharedClients);
        console.log('res is: ', res);
      } catch (err) {
        console.log('err is: ', err);
      } finally {
        setIsLoading(false);
      }
    };
    getCompletedForms();
  }, []);

  // table status labels
  const getTypeStyle = (status) => {
    switch (status) {
      case 'Approved':
        return 'status-1';
      case 'Under Review':
        return 'status-2';
      case 'Denied':
        return 'status-3';
      default:
        return '';
    }
  };

  const handleGoClientProfile = (client, index) => {
    // console.log('client is: ', client);
    // console.log('index is: ', index);
    setClientData([client]);
    navigate('/Hub/Clients/ClientsProfile');
  };

  // loading, error screens
  if (clientDataLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }
  if (clientDataError) {
    return (
      <div>
        <Error />
      </div>
    );
  }

  return (
    <>
      <PageTopLayout
        handleSearch={handleSearch}
        searchTerm={searchTerm}
        layout={layout}
        isClientsPage={true}
        activeStatusTab={activeStatusTab}
        setActiveStatusTab={setActiveStatusTab}
        selectedStatusTab={selectedStatusTab}
        setSelectedStatusTab={setSelectedStatusTab}
      />
      <div className="balance-clients-container">
        {/* grid header */}
        <div className="balance-grid-clients-title-header">
          <p>All clients</p>
        </div>
        {/* grid table */}
        <div className="balance-grid-clients-table">
          <div className="balance-grid-clients-header">
            {/* <img src={BlueSquare} alt='Icon' /> */}
            Client name
            {/* <img src={ArrowDown} alt='Icon' /> */}
          </div>
          <div className="balance-grid-clients-header">
            Document name
            {/* <img src={ArrowDown} alt='Icon' /> */}
          </div>
          <div className="balance-grid-clients-header">
            Application status
            {/* <img src={ArrowDown} alt='Icon' /> */}
          </div>
          <div className="balance-grid-clients-header">
            Document type
            {/* <img src={ArrowDown} alt='Icon' /> */}
          </div>
          <div className="balance-grid-clients-header">
            Submission date
            {/* <img src={ArrowDown} alt='Icon' /> */}
          </div>
          <div className="balance-grid-clients-header"></div>
          {/* grid record */}
          {filteredData.map((item, index) => (
            <React.Fragment key={index}>
              <div className="balance-grid-cell balance-grid-title">
                {/* <input type='checkbox' className='balance-grid-checkbox' /> */}
                <div className="balance-grid-client-name-container">
                  <p onClick={() => handleGoClientProfile(item, index)} className="balance-grid-title balance-grid-hover-title">
                    {item?.companyName || item?.clientName || 'User'}
                  </p>
                  {/* <span className="balance-grid-company">{item?.company || 'Company'}</span> */}
                </div>
              </div>
              <div className="balance-grid-cell balance-grid-type">
                <span className="balance-grid-application-name">{item?.formTitle || 'Application Title'}</span>
              </div>
              <div className="balance-grid-cell balance-grid-used">
                <span className={`${getTypeStyle(item?.applicationStatus || 'Under Review')}`}>{item?.applicationStatus || 'Under Review'}</span>
              </div>
              <div className="balance-grid-cell balance-grid-clients-type">
                <span>{item?.templateType || 'Application'}</span>
              </div>
              <div className="balance-grid-cell balance-grid-clients-date">
                <span>{item?.timeSubmitted ? formatDate(item?.timeSubmitted) : '-'}</span>
              </div>
              <div className="balance-grid-cell">
                <Button icon={DotIcon} iconPosition="after" className="balance-grid-dot-button" />
              </div>
            </React.Fragment>
          ))}
          {filteredData?.length === 0 && (
            <div style={{ display: 'grid', gridColumn: 'span 5', placeItems: 'center', color: 'gray', fontStyle: 'italic', padding: '1.5rem' }}>No applications to display</div>
          )}
        </div>
        {/* grid footer */}
        <div className="balance-grid-footer">
          <div className="balance-grid-footer-pages">Page 1 of 1</div>
          <div className="balance-grid-footer-button">
            <Button buttonName="Previous" className="balance-grid-footer-previous-button" />
            <Button buttonName="Next" className="balance-grid-footer-next-button" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
