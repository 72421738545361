import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ServerClient from '../../services/api-client';
import useFormBuilderStore from '../../store/useFormBuilderStore';
import useUserStore from '../../store/useUserStore';
import Select from 'react-select';
import { useSocket } from '../../SocketContext';
import S3ServerClient from '../../services/s3-client';
import { type } from '@testing-library/user-event/dist/type';
// components
import Modal from '../../components/Popup/Modal';
import SubmitFormPopup from '../../components/Popup/SubmitFormPopup';
import Button from '../../components/Button/Button';
import LoadingPopup from '../../components/Popup/LoadingPopup';
import DocumentUploadInput from './DocumentUploadInput';
import ThankYouPage from './ThankYouPage';
import Footer from '../../components/Footer/Footer';
import ConfirmPopup from '../../components/Popup/ConfirmPopup';
// css
import './FormPortal.css';

const FormPortal = () => {
  const [formData, setFormData] = useState([]);
  const [userToSubmitTo, setUserToSubmitTo] = useState('');
  const [clientName, setClientName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [submitModalOpen, setSubmitModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const [isFormLoading, setIsFormLoading] = useState(true);
  const [pendingFormData, setPendingFormData] = useState({});
  const [documentUploads, setDocumentUploads] = useState([]);
  const [submissionInputError, setSubmissionInputError] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [expirationError, setExpirationError] = useState(false);
  const { userId, userName } = useUserStore();
  const { link } = useFormBuilderStore(); // just to display on console

  const { socket } = useSocket();
  const navigate = useNavigate();

  // get data from mongo / grab "uniqueId" in the link and display
  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id') ? urlParams.get('id').split(',') : [];
        // let user = urlParams.get('user') ? urlParams.get('user').split(',') : [];
        // user = user[0].replaceAll(' ', '+'); // a '+' in the URL gets decoded as a space, but we want the '+' to be there still
        // if (user) {
        //   setUserToSubmitTo(user);
        // } else {
        //   throw new Error('Unable to retrieve user to return information to.');
        // }
        // const serverClient = new ServerClient('/api/getTemplateById');
        const serverClient = new ServerClient('/api/getFormPortalById');
        const res = await serverClient.post({ id: id?.[0] });
        console.log('formPortal res is: ', res);

        if (res.data?.length === 0) {
          throw new Error('Unable to retrieve template from database.');
        }
        setFormData(res.data.templatesData);
        setPendingFormData(res.data.formData[0]);  // the name seems a bit backwards but this is correct
        setUserToSubmitTo(res.data.formData[0].destinationBankerId);
      } catch (error) {
        console.error('Error fetching data:', error);
        setExpirationError(true);
      }
    };
    fetchFormData();
  }, []);

  // upload image
  // const handleClickImageUpload = (index) => {
  //   setImageUploaded((prev) => ({
  //     ...prev,
  //     [index]: true,
  //   }));
  // };

  // const handleFileSelection = async (event) => {
  //   console.log('event.target.files: ', event.target?.files);
  //   const formData = new FormData();
  //   formData.append('file', event.target?.files[0]);
  //   try {
  //     const serverClient = new S3ServerClient('/api/saveFile');
  //     const res = await serverClient.post(formData);
  //     console.log('res is: ', res);
  //   } catch (err) {
  //     console.log('err in sending file: ', err);
  //   }
  // }

  const setAssociatedInput = (inputId, data) => {
    setDocumentUploads([...documentUploads, data]);
    const input = document.getElementById(inputId); // get the input element
    input.setAttribute('value', data); // save the data passed from DocumentUploadInput as it's value for when we submit
  };

  // submit modal
  const handleClickSubmitModal = () => {
    setSubmitModalOpen(true);
    // console.log('generated link:', link);
  };
  const closeSubmitModal = () => {
    setClientName('');
    setCompanyName('');
    setSubmissionInputError(false);
    setSubmitModalOpen(false);
  };

  // finish button
  const handleClickFinishConfirm = async () => {
    const assignedDate = new Date().toISOString();

    setSubmissionInputError(false);
    if (clientName?.length < 2) {
      setSubmissionInputError(true);
      return;
    }
    setIsLoading(true); // loader
    setSubmitModalOpen(false); // modal
    // console.log('Total form data is: ', formData);
    const sectionsData = JSON.parse(formData[0]?.sectionsData);
    // console.log('sectionsData is: ', sectionsData);
    const form = document.querySelector('#formPortal');
    const formInfo = new FormData(form); // get the form info

    let counter = 0;
    const userInputs = [...formInfo];
    console.log('userInputs is: ', userInputs);
    for (const section of sectionsData) {
      // now we need to add the results to our sectionsData
      for (const question of section?.sectionInputs) {
        // for every question
        question.inputUserAnswer = userInputs[counter][1]; // add the result of the input as inputUserAnswer
        question.inputChoices = []; // no longer care about multiple choices (is this a bad idea?)
        counter += 1;
      }
    }
    // console.log(sectionsData);

    const newFormData = {
      bankerId: userId,
      sectionsData: sectionsData,
      formTitle: formData[0]?.formTitle,
      uniqueId: formData[0]?.uniqueId,
      templateType: formData[0]?.templateType,
      clientName: clientName,
      companyName: companyName,
      uploadedFiles: JSON.stringify(documentUploads),
      applicationStatus: 'Under Review',
      timeSubmitted: Date.now(),
    };
    // console.log('newFormData', newFormData);
    try {
      const serverClient = new ServerClient('/api/db/submitForm');
      const res = await serverClient.post({ form: newFormData, bankerId: userToSubmitTo, pendingFormData: pendingFormData }); // send the data to the backend

      // notification
      const notification = {
        receiverId: userId,
        senderName: userName,
        clientName: clientName,
        uniqueId: newFormData.uniqueId,
        assignedDate: assignedDate,
        message: `${userName} has submitted a form.`,
      };
      console.log('socket', socket);
      socket.emit('clientApplicationSubmission', notification);
      console.log('notification', notification);

      setIsSubmitted(true);
      // navigate('/Form/Thank-You');
    } catch (err) {
      navigate('/Form/Error');
    } finally {
      setIsLoading(false);
    }
  };

  if (isSubmitted) {
    return <ThankYouPage forClientPortal={true} />;
  }

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'clientName') {
      setClientName(value);
    } else if (name === 'companyName') {
      setCompanyName(value);
    }
  };

  // * * * test use
  // const targetId = '660e0cac4341ac47413b6dd2';
  // const testData = formData.find((data) => data._id === targetId);
  // console.log('formData:', formData);

  return (
    <div className="balance-form-portal-container">
      <div className="form-portal-header-container">
        <div className="form-portal-header-inner-container">
          <div className="form-portal-header-org-name-container">
            {formData.length > 0 && formData[0] ? <p className="form-portal-header-org-name">{formData[0].companyName}</p> : expirationError ? <p></p> : <p>Loading...</p>}
          </div>
          <div className="form-portal-header-template-name-container">
            {formData.length > 0 && formData[0] ? <p className="form-portal-header-template-name">{formData[0].formTitle}</p> : expirationError ? <p></p> : <p>Loading...</p>}
          </div>
        </div>
        <div className="form-portal-header-buttons-container">
          <Button className="form-portal-header-cancel-button form-portal-header-button" buttonName={'Cancel'} disabled={expirationError} />
          <Button className="form-portal-header-submit-button form-portal-header-button" buttonName={'Finish'} onClick={handleClickSubmitModal} disabled={expirationError} />
        </div>
      </div>
      <div className="form-portal-form-area-container">
        {expirationError && <div className='form-portal-expiration-container'><h2 className='form-portal-expiration-text'>This link has expired or has already been submitted.<br></br>Please contact your banker if you believe this to be a mistake.</h2></div>}
        <form className="form-portal-form-container" id="formPortal">
          {formData.map((data, dataIndex) => (
            <div key={dataIndex}>
              {/* sections */}
              {data.sectionsData &&
                JSON.parse(data.sectionsData).map((section, sectionIndex) => (
                  <div key={sectionIndex} className="section-container">
                    <p className="form-portal-form-title">{section.sectionTitle}</p>
                    <p className="form-portal-form-description">{section.sectionDesc}</p>
                    {/* inputs */}
                    {section.sectionInputs.map((input, inputIndex) => (
                      <div key={inputIndex} className="form-portal-form-input-container">
                        {/* all label */}
                        <label className="form-portal-form-input-title">{input.inputTitle}</label>
                        {/* short text */}
                        {input.inputType === 'shortText' && <input type="text" name={input.inputTitle} placeholder={input.inputPlaceholder} className="short-text-style" />}
                        {/* long text */}
                        {input.inputType === 'longText' && <input type="text" name={input.inputTitle} placeholder={input.inputPlaceholder} className="long-text-style" />}
                        {/* dropdown */}
                        {input.inputType === 'longDropD' && <Select name={input.inputTitle} options={input?.inputChoices} className="dropdown-style" />}
                        {/* documents */}
                        {input.inputType === 'documents' && (
                          <>
                            <input
                              id={`${sectionIndex}-${inputIndex}`}
                              type="text"
                              // accept='image/*'
                              name={input?.inputTitle || `Document-${sectionIndex}-${inputIndex}`}
                              style={{ display: 'none' }}
                            />
                            <DocumentUploadInput inputId={`${sectionIndex}-${inputIndex}`} callbackFunc={setAssociatedInput} />
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                ))}
            </div>
          ))}
        </form>
      </div>
      {submitModalOpen && (
        <Modal width="40rem" onClose={closeSubmitModal}>
          <SubmitFormPopup
            onClose={closeSubmitModal}
            onConfirm={handleClickFinishConfirm}
            checkConfirm="Please enter your full name to confirm your submission."
            inputOnChange={handleChangeInput}
            inputError={submissionInputError}
            clientName={clientName}
            companyName={companyName}
          />
        </Modal>
      )}
      {isLoading && (
        <Modal width="32rem" onClose={() => setIsLoading(false)}>
          <LoadingPopup />
        </Modal>
      )}
      <Footer />
    </div>
  );
};

export default FormPortal;
