import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useSendSignupEmails, useCheckNewUserEmail, useDecodeEmail, useDomainCheck } from '../../../hooks/useEmailHooks';
import { useRetrieveOrgInfo } from '../../../hooks/useUserHooks';
import { useCreateUser } from '../../../hooks/useUserHooks';
import FormHeader from '../../../components/Form/FormHeader';
import Page1 from './SignUpPage1';
import Page2 from './SignUpPage2';
import Page3 from './SignUpPage3';
import SignUpPassword from './SignUpPassword';
import JoinOrg from './JoinOrg';
import JoinOrgSuccess from './JoinOrgSuccess';
import FormFooter from '../../../components/Form/FormFooter';
import ThankYouPage from '../../FormPortal/ThankYouPage';
import FormLogo from '../../../components/Form/FormLogo';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './SignUp.css';
import useSignUpStore from '../../../store/useSignupStore';

export const SignUp = () => {
  const [emails, setEmail] = useState([]);
  const [usedEmail, setUsedEmail] = useState(''); // email already in use
  const [excludedEmail, setExcludedEmail] = useState(''); // email excluded domain
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false); // work emaii input page
  const [isLoadingDone, setLoadingDone] = useState(false); // company input page
  const [invalidEmail, setInvalidEmail] = useState('');
  const [invalidPassword, setInvalidPassword] = useState('');
  const [isThankYouPage, setThankYouPage] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null); // dropdown
  const [orgInfo, setOrgInfo] = useState(null); // join org data
  // const [companyAddress, setCompanyAddress] = useState({ address1: '', address2: '', streetAddress: '' });
  // const [accountCreated, setAccountCreated] = useState(false);
  // const [apiError, setApiError] = useState('');
  // const [companyList, setCompanyList] = useState([{ id: null, companyName: null }]);
  // const [isAdmin, setIsAdmin] = useState(null);
  // const [verificationCode, setVerificationCode] = useState('');
  // const [enteredCode, setEnteredCode] = useState('');
  // const [verificationError, setVerificationError] = useState('');

  const { data: orgData, isLoading: isLoadingData, error } = useRetrieveOrgInfo();
  const { mutate: sendEmailMutate } = useSendSignupEmails();
  const { mutate: checkNewUserEmailMutate } = useCheckNewUserEmail();
  const { mutate: decodeEmailMutate } = useDecodeEmail();
  const { mutate: domainCheckMutate } = useDomainCheck();
  const { mutate: createUserMutate } = useCreateUser();

  const { decodedEmail, setDecodedEmail, firstName, lastName, jobTitle, jobDepartment, password, confirmPassword, companyName, setCompanyName } = useSignUpStore();

  const location = useLocation();
  // console.log('decodedEmail', decodedEmail);

  useEffect(() => {
    if (orgData) {
      setOrgInfo(orgData);
    }
  }, [orgData]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const emailParams = params.get('user');

    if (emailParams) {
      // link includes user displays page === 2
      setEmail([decodeURIComponent(emailParams)]);
      setPage(2);

      decodeEmailMutate(
        { emailParams },
        {
          onSuccess: (data) => {
            console.log('data', data);
            setDecodedEmail(data.email);
            setPage(2);
          },
          onError: (error) => {
            console.log('Error ', error);
          },
        }
      );
    }
  }, [location, decodeEmailMutate]);

  // password rules
  const validatePassword = (password) => {
    const minLength = /.{6,}/;
    const number = /[0-9]/;
    const uppercase = /[A-Z]/;
    const specialChar = /[_!@#$%]/;

    if (!minLength.test(password)) {
      return 'Must be at least 6 characters.';
    }
    if (!number.test(password)) {
      return 'Must contain at least one number.';
    }
    if (!uppercase.test(password)) {
      return 'Must contain at least one uppercase letter.';
    }
    if (!specialChar.test(password)) {
      return 'Must contain a special character (_!@#$%).';
    }
    return '';
  };

  const handlePasswordOnBlur = () => {
    if (password?.length < 6) {
      setInvalidPassword('Password must be at least 6 characters.');
    } else {
      setInvalidPassword('');
    }
  };

  const handleChangeEmail = (e) => {
    setEmail([e.target.value]);
    setInvalidEmail('');
    setUsedEmail('');
    setExcludedEmail('');
  };

  // work email next (page 1)
  const handleNextButtonClick = async () => {
    if (!emails) {
      setInvalidEmail('Email required.');
      return;
    }

    setInvalidEmail('');
    setIsLoading(true);

    const imagePath = '/Logo.png';

    try {
      const response = await fetch(imagePath);
      if (!response.ok) {
        throw new Error('Failed to fetch image.');
      }
      console.log('Fetched response:', response);

      const blob = await response.blob();
      // console.log('Blob:', blob);

      if (blob.type.startsWith('text')) {
        throw new Error('Fetched file is not an image.');
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = reader.result;
        // console.log('Base64:', imageData);

        checkNewUserEmailMutate(
          { email: emails[0] },
          {
            onSuccess: () => {
              console.log('emails', emails);
              sendEmailMutate(
                { emails, image: imageData },
                {
                  onSuccess: () => {
                    setEmail('');
                    setIsLoading(false);
                    setThankYouPage(true);
                    console.log('Email sent to the user.');
                  },
                  onError: (error) => {
                    if (error) {
                      setExcludedEmail('Please enter your work email.');
                    } else {
                      console.log('Email sending failed:', error);
                    }
                    setIsLoading(false);
                  },
                }
              );
            },
            onError: (error) => {
              if (error.response?.status === 401) {
                // console.log('Email already in use.');
                setUsedEmail('Email already in use.');
              } else {
                console.log('Email check failed:', error);
              }
              setIsLoading(false);
            },
          }
        );
      };
      reader.readAsDataURL(blob);
    } catch (error) {
      console.log('Error loading image:', error);
      setIsLoading(false);
    }
  };
  if (isThankYouPage) {
    return <ThankYouPage message="Please check your email inbox to continue the Balance sign-up process and exit this window." />;
  }

  // almost there next (page 2)
  const almostThereOnNext = async () => {
    if (!firstName || !lastName || !jobTitle || !selectedOption || !jobDepartment) {
      setInvalidEmail('All fields required.');
      return;
    }
    setPage(4);
  };

  // done (page 3)
  const handleClickDone = () => {
    if (!companyName) {
      // if (!companyName || !companyAddress.address1 || !companyAddress.address2 || !companyAddress.streetAddress) {
      setInvalidEmail('Company name is required.');
      return;
    }

    setLoadingDone(true);

    createUserMutate(
      {
        decodedEmail,
        name: `${firstName} ${lastName}`,
        password,
        jobTitle,
        selectedOption: selectedOption.label,
        jobDepartment,
        companyName,
        // companyAddress: `${companyAddress.address1} ${companyAddress.address2}, ${companyAddress.streetAddress}`,
      },
      {
        onSuccess: () => {
          setPage(6);
          // toast('Account Created! Please Log In.', {
          //   position: 'top-left',
          //   autoClose: 3000,
          //   closeButton: false,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   transition: Slide,
          //   theme: 'light',
          // });
          // setAccountCreated(true);
        },
        onError: (error) => {
          console.log('Error creating an account.', error);
        },
      }
    );
  };

  // password complete (page 4)
  const onCompletePassword = () => {
    // check password both matches
    if (password !== confirmPassword) {
      setInvalidPassword('Passwords do not match.');
      return;
    }

    const passwordRules = validatePassword(password);
    if (passwordRules) {
      setInvalidPassword(passwordRules);
      return;
    }

    domainCheckMutate(
      { decodedEmail },
      {
        onSuccess: (data) => {
          // console.log('decodedEmail', decodedEmail);
          if (data.exists) {
            setPage(5);
          } else {
            setPage(3);
          }
        },
        onError: (error) => {
          console.log('Domain check failed:', error);
        },
      }
    );
  };

  // join team (page 5)
  const onJoinTeam = () => {
    createUserMutate(
      { decodedEmail, name: `${firstName} ${lastName}`, password, jobTitle, selectedOption: selectedOption.label, jobDepartment },
      {
        onSuccess: () => {
          setPage(6);
          // toast('Account Created! Please Log In.', {
          //   position: 'top-left',
          //   autoClose: 3000,
          //   closeButton: false,
          //   hideProgressBar: true,
          //   closeOnClick: true,
          //   pauseOnHover: true,
          //   transition: Slide,
          //   theme: 'light',
          // });
          // setAccountCreated(true);
          // console.log('Account Created.');
        },
        onError: (error) => {
          console.log('Error creating an account.', error);
        },
        onError: (error) => {
          console.log('Error fetching company ID:', error);
        },
      }
    );
  };

  // dropdown options
  const options = [
    { value: 'analyst', label: 'Analyst' },
    { value: 'manager', label: 'Manager' },
    { value: 'executive ', label: 'Executive ' },
  ];
  const handleChangeOptions = (option) => {
    setSelectedOption(option);
  };

  // useEffect(() => {
  //   const getCompanies = async () => {
  //     try {
  //       const serverClient = new ServerClient('/api/db/companiesList');
  //       const res = await serverClient.get();
  //       setCompanyList(res?.data?.data);
  //     } catch (err) {
  //       console.log('Company API Error');
  //       setApiError('Company API Error');
  //     }
  //   };
  //   if (companyList?.length <= 1) {
  //     // don't need to keep recalling api over and over
  //     getCompanies();
  //   }
  //   if (accountCreated) {
  //     toast('Account Created! Please Log In.', {
  //       position: 'top-left',
  //       autoClose: 3000,
  //       closeButton: false,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       transition: Slide,
  //       theme: 'light',
  //     });
  //   }
  // }, [accountCreated]);

  // const handleCompanyDropdownChange = (company) => {
  //   setCompany(company);
  // };
  // const handleAdminDropdownChange = (adminChoice) => {
  //   setIsAdmin(adminChoice);
  // };
  // const handleVerificationCodeInput = (code) => {
  //   if (code?.length > 6) {
  //     return;
  //   }
  //   setEnteredCode(code);
  // };

  // const onVerificationSubmit = async () => {
  //   try {
  //     if (enteredCode !== verificationCode) {
  //       setVerificationError('Incorrect Code');
  //       return;
  //     }
  //     setVerificationError('');
  //     // code is correct, now save the information!
  //     const serverClient = new ServerClient('/api/db/createUser');
  //     const serverRes = await serverClient.post({
  //       email: email,
  //       name: `${firstName} ${lastName}`,
  //       companyId: company?.id,
  //       isAdmin: isAdmin?.value,
  //       password: password,
  //     });
  //     setAccountCreated(true);
  //   } catch (err) {
  //     console.log('err is: ', err);
  //     setVerificationError('Account creation unavailable.');
  //   }
  // };

  // const handleClickCreate = async () => {
  //   // console.log('Email:', email);
  //   if (!email) {
  //     setInvalidEmail('All fields required.');
  //     return;
  //   }
  //   setInvalidEmail('');
  //   setIsLoading(true);

  // try {
  //   const serverClient = new ServerClient('/api/db/checkNewUserEmail');
  //   const res = await serverClient.post({ email: email });
  //   setPage(2);
  // } catch (err) {
  //   console.log('Error response:', err.response);
  //   if (err.response?.status === 401) {
  //     setInvalidEmail('Email already in use.');
  //   } else {
  //     setInvalidEmail('An unexpected error occurred.');
  //   }
  // } finally {
  //   setIsLoading(false);
  // }
  // };

  return (
    <div className="balance-sign-up-container">
      <ToastContainer
        toastStyle={{
          color: '#000000',
          fontWeight: '600',
          textAlign: 'center',
          background: '#d1ffd9',
        }}
      />
      <div className="balance-sign-up-inner-container">
        <FormHeader />
        {page === 1 && (
          <Page1
            invalidEmail={invalidEmail}
            usedEmail={usedEmail}
            excludedEmail={excludedEmail}
            isLoading={isLoading}
            setInvalidEmail={setInvalidEmail}
            setEmail={setEmail}
            handleChangeEmail={handleChangeEmail}
            handleNextButtonClick={handleNextButtonClick}
          />
        )}
        {page === 2 && (
          <Page2
            invalidEmail={invalidEmail}
            decodedEmail={decodedEmail}
            invalidPassword={invalidPassword}
            setInvalidEmail={setInvalidEmail}
            handlePasswordOnBlur={handlePasswordOnBlur}
            almostThereOnNext={almostThereOnNext}
            options={options}
            handleChangeOptions={handleChangeOptions}
            selectedOption={selectedOption}
          />
        )}
        {page === 3 && (
          <Page3
            invalidEmail={invalidEmail}
            companyName={companyName}
            // companyAddress={companyAddress}
            isLoadingDone={isLoadingDone}
            setInvalidEmail={setInvalidEmail}
            setCompanyName={setCompanyName}
            // setCompanyAddress={setCompanyAddress}
            handleClickDone={handleClickDone}
          />
        )}
        {page === 4 && (
          <SignUpPassword
            isLoadingData={isLoadingData}
            invalidPassword={invalidPassword}
            setInvalidPassword={setInvalidPassword}
            handlePasswordOnBlur={handlePasswordOnBlur}
            onCompletePassword={onCompletePassword}
          />
        )}
        {page === 5 && <JoinOrg onJoinTeam={onJoinTeam} orgInfo={orgInfo} />}
        {page === 6 && <JoinOrgSuccess onJoinTeam={onJoinTeam} />}
        {/* {page === 3 && (
          <div className="balance-sign-up-left-container">
            <div className="balance-sign-up-title-container">
              <h1 className="balance-sign-up-title">Account Verification</h1>
              <p className="balance-sign-up-sub-title">Please enter the verification code we've sent to your email to complete the sign up process.</p>
            </div>
            <div className="balance-global-form balance-signup-input-container">
              <label htmlFor="name" className="balance-global-label">
                <div className="balance-label-left-side">
                  Confirmation Code *
                </div>
                {verificationError && <p className="balance-label-error-info">{verificationError}</p>}
              </label>
              <input
                type="text"
                id="verificationCode"
                value={enteredCode || ''}
                placeholder="Enter confirmation code"
                className="balance-global-input"
                disabled={accountCreated}
                onChange={(e) => handleVerificationCodeInput(e.target.value)}
              />
              <Button
                className={`balance-global-blue-button balance-global-movable-button ${isLoading || accountCreated ? 'balance-global-disabled-button' : undefined}`}
                buttonName={isLoading ? 'Loading' : 'Confirm Email'}
                disabled={isLoading || accountCreated}
                onClick={onVerificationSubmit}
              />
              {accountCreated && (
                <div className="balance-sign-up-container">
                  <p className="balance-global-gray-question">Account Created!</p>
                  <Button onClick={() => navigate('/Login')} buttonName="Log in" className="balance-global-blue-navigate-button" />
                </div>
              )}
            </div>
          </div>
        )} */}
      </div>
      <FormFooter />
      {/* right container */}
      <div className="balance-sign-up-right-container">
        <FormLogo />
      </div>
    </div>
  );
};

export default SignUp;
