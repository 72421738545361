import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import useUserStore from '../../../store/useUserStore';
// hook
import { useSocket } from '../../../SocketContext';
import { useRetrieveCoworkers } from '../../../hooks/useTemplateHooks';
import { useGetClientHubForms } from '../../../hooks/useClientHooks';
import { useAssignTasks } from '../../../hooks/useTaskHooks';
import Button from '../../../components/Button/Button';
import Modal from '../../../components/Popup/Modal';
import ConfirmPopup from '../../../components/Popup/ConfirmPopup';
import './AssignTask.css';
import LeftArrow from '../../../assets/Icon/Arrow_Left_Dark.svg';

const AssignTask = () => {
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [filteredFormTitles, setFilteredFormTitles] = useState([]);
  const { data: coworkersData, isLoading: coworkersLoading, isError: coworkerError } = useRetrieveCoworkers();
  const { mutate } = useAssignTasks();
  const { userId, userName, setTaskData } = useUserStore();
  const { data: clientCompletedFormData } = useGetClientHubForms();

  const { socket } = useSocket();
  const navigate = useNavigate();

  // react hook form
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    reset,
    control,
    watch,
    formState: { errors },
  } = useForm({ mode: 'onChange' });

  // send button to open popup
  const onSubmit = (data) => {
    setConfirmModalOpen(true);
    // setTaskData(data);
  };
  // assign action
  const handleConfirm = () => {
    const assignedDate = new Date().toISOString();
    const taskData = getValues(); // get values from react hook form

    if (taskData) {
      const selectedForm = filteredFormTitles.find((form) => form.value === taskData.applicationName.value);
      const templateType = selectedForm ? selectedForm.templateType : '';

      // data
      const newTask = {
        userId: userId,
        userName: userName,
        assignedTo: taskData.assign.label,
        assignedToId: taskData.assign.value,
        applicationName: taskData.applicationName.label,
        client: taskData.clientName.label,
        description: taskData.description,
        status: 'Incomplete',
        templateType: templateType,
        creatorDeleted: false,
      };

      mutate(newTask, {
        // mutate into tasks collection
        onSuccess: (res) => {
          setConfirmModalOpen(false);
          reset({
            clientName: null,
            applicationName: null,
            assign: null,
            description: '',
          });
          setTaskData(res.data);
          navigate('/Hub/Tasks#Assigned');

          // data
          const notification = {
            userAssignedId: taskData.assign.value,
            senderName: userName,
            assignedDate: assignedDate,
            message: `${userName} assigned a task to you.`,
          };
          console.log('socket', socket);
          socket.emit('taskAssigned', notification);
        },
        onError: (error) => {
          console.error('Error inserting task:', error);
        },
      });
    }
  };

  // popup modal
  const closeConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  // client name dropdown
  const clientNameOptions =
    clientCompletedFormData?.completedForms.map((form) => ({
      value: form.uniqueId,
      label: form.clientName,
    })) || [];

  const selectedClient = watch('clientName');
  const selectedApplication = watch('applicationName');

  useEffect(() => {
    if (selectedClient && clientCompletedFormData.completedForms) {
      const relatedForms = clientCompletedFormData.completedForms.filter((form) => form.clientName === selectedClient.label);
      const formTitleOptions = relatedForms.map((form) => ({
        value: form.uniqueId,
        label: form.formTitle,
        templateType: form.templateType,
      }));
      setFilteredFormTitles(formTitleOptions);
      // reset when other client is selected from dropdown
      setValue('applicationName', '');
    }
  }, [selectedClient, clientCompletedFormData]);

  // co worker dropdown
  const coworkerOptions = coworkersData && Array.isArray(coworkersData.data) ? coworkersData.data.map((coworker) => ({ value: coworker.userId, label: coworker.name })) : [];

  // view task - status dropdown
  // const options = [
  //   { value: 'Incomplete', label: 'Incomplete' },
  //   { value: 'In-progress', label: 'In-progress' },
  //   { value: 'Completed', label: 'Completed' },
  // ];

  // no client name data, application name data, use for alert
  const isFormValid = selectedClient && selectedApplication;

  return (
    <div className="balance-task-container">
      <div className="balance-title-container">
        <img src={LeftArrow} alt="ArrowIcon" className="balance-task-arrow-icon" onClick={() => navigate('/Hub/Tasks')} />
        <h1 className="balance-global-title">Assign Task</h1>
      </div>
      <div className="balance-task-inner-container">
        <div className="balance-assign-task-form-container">
          <div className="balance-assign-task-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                {/* client name field */}
                <label htmlFor="clientName" className="balance-assign-task-label">
                  Client name
                </label>
                <Controller
                  name="clientName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Select {...field} id="clientName" placeholder="Select client name" className="balance-assign-task-select" options={clientNameOptions} />}
                />
                {/* application name field */}
                <label htmlFor="applicationName" className="balance-assign-task-label">
                  Application name
                </label>
                <Controller
                  name="applicationName"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Select {...field} id="applicationName" placeholder="Select application name" className="balance-assign-task-select" options={filteredFormTitles} />}
                />
                {/* assignee field */}
                <label htmlFor="assign" className="balance-assign-task-label">
                  Assignee
                </label>
                <Controller
                  name="assign"
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => <Select {...field} id="assign" placeholder="Select Assignee" className="balance-assign-task-select" options={coworkerOptions} />}
                />
              </div>
              <div className="balance-assign-task-description-container">
                <label htmlFor="description" className="balance-assign-task-label">
                  Task Description
                </label>
                <textarea
                  id="description"
                  cols="30"
                  rows="5"
                  placeholder="Enter a task description."
                  className="balance-assign-task-textarea"
                  {...register('description', { required: true })}
                ></textarea>
              </div>
              {confirmModalOpen && (
                <Modal width="32rem" onClose={closeConfirmModal}>
                  <ConfirmPopup onClose={closeConfirmModal} onConfirm={handleConfirm} checkConfirm="Are you sure you want to confirm?" />
                </Modal>
              )}
            </form>
            <Button
              buttonName="Send"
              className="balance-task-send-button balance-global-blue-button balance-global-movable-button"
              onClick={() => {
                isFormValid ? setConfirmModalOpen(true) : alert('Client and Application are not available.');
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignTask;
