import { useNavigate } from 'react-router-dom';
import Button from '../../../components/Button/Button';
import './JoinOrgSuccess.css';

const JoinOrgSuccess = () => {
  const navigate = useNavigate();

  return (
    <div className="balance-sign-up-left-container">
      <div className="balance-global-form balance-signup-input-container">
        <div className="balance-join-org-title-container balance-sign-up-title-container">
          <h1 className="balance-sign-up-title">Success</h1>
          <p className="balance-sign-up-sub-title">Your account has been created. Please login.</p>
        </div>
        <div className="balance-join-org-success-button-container">
          <Button buttonName="Login" className="balance-global-blue-navigate-button" onClick={() => navigate('/Login')} />
        </div>
      </div>
    </div>
  );
};

export default JoinOrgSuccess;
